<template>
    <div>
      <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false" >
        العيادة الغير نشطة
      </dashboard-page-title>
          <b-row md="9">
            <b-col md="9">
              <b-card>
                <b-row>
                  <b-col md="2">
                    <img :src="clinicData.logo" alt="logo of hospital" class="w-75 h-75 rounded-circle"/>
                  </b-col>
                  <b-col md="4" class="mb-3">
                    <input-form placeholder="اسم العيادة" label="اسم العيادة" v-model="clinicData.clinic_name" disabled name="title" validate="required"></input-form>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6" class="mb-3">
                    <input-form placeholder="عنوان العيادة" label="عنوان العيادة" v-model="clinicData.clinic_address" disabled name="title" validate="required"></input-form>
                  </b-col>
                  <b-col md="6" class="mb-3">
                    <input-form placeholder="اسم مالك العيادة" label="اسم مالك العيادة" v-model="clinicData.clinic_owner_name" disabled name="title" validate="required"></input-form>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6" class="mb-3">
                    <input-form placeholder="رقم الهاتف" label="رقم الهاتف" v-model="clinicData.primary_phone_number" disabled name="title" validate="required"></input-form>
                  </b-col>
                  <b-col md="6" class="mb-3">
                    <input-form placeholder="رقم الهاتف الثانوى" label="رقم الهاتف الثانوى" v-model="clinicData.secondary_phone_number" disabled name="title" validate="required"></input-form>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12" class="mb-3">
                    <textarea-form placeholder="الوصف" label="الوصف" v-model="clinicData.description" disabled name="title" validate="required"></textarea-form>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                  <div
                      class="setting-box-inner d-flex align-items-center gap_2"
                    >
                      <p class="setting-box-title">نسبة العمولة</p>
                      <div class="d-flex align-items-center gap_1">
                        <input-form
                          class="text-center m-0 matone-value"
                          placeholder=""
                          v-model="clinicData.fees"
                          disabled
                          name="clinic_examine"
                        />
                        <span class="small-text">%</span>
                      </div>
                    </div>
                </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col md="3">
              <b-card v-for="item in clinicData.uploaded_documents" :key="item.id" class="mb-4">
                <div>
                  <span>{{ item.doc.title }}</span>
                  <hr />
                  <div class="d-flex gap-3" style="gap: 10px;">
                    <img :src="item.url" class="rounded-circle object-fit-cover" style="width: 80px; height: 40px;"/>
                    <b-button variant="outline-primary" id="show-btn" @click="showModal(item)">عرض الصورة</b-button>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col md="9" class="d-flex justify-content-center align-items-center" style="gap: 10px;">
              <div>
                <b-button variant="primary" class="px-5 py-3" id="show-btn" v-if="!loadingAccept" @click="acceptClinic">قبول</b-button>
                <b-button v-else variant="primary" class="text-white px-5 py-3" disabled type="submit">
                  <b-spinner small type="grow"></b-spinner>
                  Loading...
                </b-button>
              </div>
              <div>
                <b-button variant="outline-danger" class="px-5 py-3" id="show-btn" v-if="!loadingReject" @click="showSuspensedModal">رفض</b-button>
                <b-button v-else variant="outline-danger" class="text-white px-5 py-3" disabled type="submit">
                  <b-spinner small type="grow"></b-spinner>
                  Loading...
                </b-button>
              </div>
            </b-col>
          </b-row>

      <b-modal ref="modal-photo" id="modal-photo" centered hide-footer>
        <template #modal-title>
          {{ selectedPhoto.title }}
        </template>
        <div class="image-container d-block text-center">
          <img :src="selectedPhoto.url" class="img-fluid"/>
        </div>
      </b-modal>

      <b-modal id="suspendedModal" size="lg" title="سبب ايقاف العيادة" style="direction: rtl" centered hide-footer>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <b-row class="d-flex flex-wrap">
              <b-col>
                <textarea-form placeholder="السبب" v-model="reason" label="سبب مختصر" name="reason" validate="required"></textarea-form>
              </b-col>
            </b-row>
          </form>
        </ValidationObserver>

          <div class="d-flex justify-content-center mt-2">
            <b-button v-if='saveButton' class="btn btn-primary float-right" variant="primary" disabled>
              <spinner-loading text = 'Loading' />
            </b-button>
            <b-button variant="primary" v-else class="w-50" @click="handleSuspendClinic">Save</b-button>
          </div>
      </b-modal>
    </div>
  </template>
<script>
import { core } from '@/config/pluginInit'
import clinicServices from '../services/index'
import commonMixin from '@/mixins/commonMixin'
export default {
  components: { },
  mounted () {
    core.index()
  },
  mixins: [commonMixin],
  data () {
    return {
      reason: '',
      suspendedId: '',
      saveButton: false,
      selectedPhoto: {},
      loadingAccept: false,
      loadingReject: false,
      // loadingLogo: 0,
      clinicData: {
        title: ''
      },
      rejectlinic (id, status) {
        this.loadingAccept = true
        clinicServices.statusClinic(id, status).then(res => {
          core.showSnackbar('success', res.data.message)
          this.$router.push({ name: 'inActiveClinics' })
        }).finally(() => {
          this.loadingAccept = false
        })
      }
    }
  },
  methods: {
    getClinic () {
      clinicServices.getClinic(this.$route.params.id).then(res => {
        this.clinicData = res.data
      })
    },
    showModal (item) {
      this.$refs['modal-photo'].show()
      this.selectedPhoto = { url: item.url, title: item.doc.title }
    },
    showSuspensedModal () {
      this.suspendedId = this.$route.params.id
      this.$bvModal.show('suspendedModal')
    },
    acceptClinic () {
      this.loadingAccept = true
      clinicServices.acceptClinic(this.$route.params.id).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$router.push({ name: 'inActiveClinics' })
      }).finally(() => {
        this.loadingAccept = false
      })
    },
    handleSuspendClinic () {
      this.saveButton = true
      clinicServices.statusClinic(this.suspendedId, 'rejected', { mail_body: this.reason }).then(res => {
        core.showSnackbar('success', 'تم  رفض العيادة بنجاح')
        this.saveButton = false
        this.$bvModal.hide('suspendedModal')
        this.$router.push({ name: 'inActiveClinics' })
      })
    },
    onSubmit () {}
  },
  created () {
    if (this.$route.params.id) {
      this.getClinic()
      this.$root.$on('showSuspensedModal', this.showSuspensedModal)
    }
  }
}
</script>

<style>
  .vs__dropdown-toggle {
    background: #fff !important;
    border-radius: 10px !important;
  }
  .image-container {
    width: 100%;
  }
  .setting-box-title {
    color: #3f414d !important;
    font-weight: normal !important
  }
  .image-container img {
    width: 100%
  }
</style>
